#footer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  max-width: 100%;
  background-color: $light-gray;
  padding: 0.7rem 0;

  p {
    margin: 0;
    text-align: center;
  }
}
