html {
  font-size: 40px;

  @media (max-width: 2200px) {
    font-size: 30px;
  }

  @media (max-width: 1920px) {
    font-size: 25px;
  }

  @media (max-width: 992px) {
    font-size: 20px;
  }

  @media (max-width: 768px) {
    font-size: 18px;
  }

  @media (max-width: 576px) {
    font-size: 16px;
  }

  @media (max-width: 340px) {
    font-size: 4vw;
  }
}
