blockquote {
  border-left: solid 0.2rem $gray;
  padding-left: 0.5rem;
  margin-block-start: auto;
  margin-block-end: auto;
  margin-inline-start: auto;
  margin-inline-end: auto;
  margin: 0 3.5rem;
  @media(max-width: 600px) {
    margin: 0 2rem;
  }

  * {
    font-family: Roboto, sans-serif;
    color: $dark-gray;
  }

  .quote {
    margin-bottom: 0.3rem;
    line-height: 1.3rem !important;
    font-family: Montserrat, sans-serif;

    .left-quote, .right-quote {
      font-family: Montserrat, sans-serif;
      font-weight: 500;
      font-size: 110%;
      color: lighten($dark-gray, 10);
    }
  }

  .quotee {
    margin-top: 0;
  }
}
