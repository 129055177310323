#post {
  margin: auto;
  margin-top: 3rem;

  h1 {
    font-weight: 500;
    font-size: 1.3rem;
    margin-bottom: 0.4rem;
    line-height: 1.1;
    text-align: center;
  }

  .subtitle {
    font-size: 1rem;
    * {
      font-family: Roboto, sans-serif;
    }
    margin-bottom: 2rem;
    text-align: center;
    span {
      &.separator {
        border-right: solid 0.05rem $black;
        margin: 0 0.5rem 0 0.1rem;
      }
      a {
        color: inherit;
      }
    }
  }

  h2 {
    margin-top: 2rem;
    font-size: 1.2rem;
    font-weight: 500;
  }

  h3 {
    margin-top: 4rem;
    margin-bottom: 0rem;
    text-align: center;
  }

  p {
    line-height: 1.5rem;
  }

  sup.footnote-ref {
    font-style: normal !important;
  }

  .footnotes {
    margin-top: 2rem;
    .footnote {
      font-size: 0.65rem;
      line-height: 1rem;
    }
  }

  .share-icons {
    margin-top: 1rem;
    text-align: center;
    .icon {
      margin: 0rem 1rem;
      display: inline-block;
      @include hover-zoom(1.1, 0.2s);
      a {
        font-size: 2rem;
        text-decoration: none;
        .fa-twitter * {
          color: #38A1F3;
        }
        .fa-facebook * {
          color: #4267B2;
        }
        .fa-reddit * {
          color: #FF4301;
        }
      }
    }
  }
}
