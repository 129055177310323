@mixin hover-zoom($scale, $speed) {
  transition: all $speed ease-in-out;
  @media (hover: hover) {
    &:hover {
      transform: scale($scale);
      cursor: pointer;
    }
  }
}

@mixin select-off {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
