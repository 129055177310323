body {
  background-color: $white;
  overflow-x: hidden;
  padding-bottom: 5rem;

  &> div {
    max-width: 1600px;
    margin: auto;
    padding: 0 2rem;
  }
}

* {
  font-family: 'Montserrat', sans-serif;
  color: $black;
}

p, ul, li, a {
  font-family: 'Roboto', sans-serif;
  line-height: 1.1;
  font-size: 1rem;
}

em, del, b, sup {
  font-family: inherit;
}

h1 {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

h2 {
  font-size: 1.75rem;
  font-weight: 500;
  margin-bottom: 1rem;
}

h3 {
  font-size: 1.4rem;
  font-weight: 500;
}

a {
  color: $blue;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &:visited {
    color: $blue;
  }
}

table {
  border-collapse: collapse;
  margin: auto;
  max-width: fit-content;
  display: block;
  overflow-x: auto;

  td, th {
    border: solid 1px $black;
    padding: 0.3rem 0.5rem;
  }
}
