#landing {
  text-align: center;

  h1 {
    border-bottom: solid;
    border-color: $black;
    border-width: 0.2rem;
    display: inline-block;
    padding: 0rem 2rem;
    margin-top: 4rem;
  }

  #icons {
    @include select-off;
    margin: auto;
    margin-top: 1.5rem;

    .icon {
      display: inline-block;
      @include hover-zoom(1.1, 0.15s);

      margin: 0rem 2rem;
      @media(max-width: 700px) {
        margin: 0rem 1.3rem;
      }

      * {
        font-size: 2.7rem;
      }

      a {
        color: inherit;
      }
    }
  }

  #blog {
    margin-top: 4rem;

    h2 {
      margin-bottom: 1rem;
    }

    #blog-list {
      text-align: left;

      a {
        color: inherit;
        text-decoration: none;

        .entry {
          border-top: 1px solid $black;
          padding: 1rem 0;

          p.entry-title {
            font-weight: 600;
            margin-bottom: 0.4rem;
          }
        }

        &:first-child {
          .entry {
            border: none;
          }
        }

        p {
          margin: 0;
        }

        &:hover {
          p.entry-title {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
